<template>
  <div style="width: 100%; height: calc(80vh - 170px)">
    <div class="row">
      <div class="col-12">
        <div id="siteMap" style="width: 100%; height: calc(80vh - 170px)"></div>

        <div id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content"></div>
        </div>
      </div>
    </div>
    <div class="row m-t-sm">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th style="width: 32px">Colour</th>
                <th>Meaning</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="fullVars > 0">
                <td>
                  <div
                    class="colorSquare"
                    style="background-color: green"
                  ></div>
                </td>
                <td style="color: white; line-height: 32px">
                  Full VARS System
                </td>
                <td>{{ fullVars }}</td>
              </tr>
              <tr v-if="tabletOnly > 0">
                <td>
                  <div
                    class="colorSquare"
                    style="background-color: orange"
                  ></div>
                </td>
                <td style="color: white; line-height: 32px">Tablet Only</td>
                <td>{{ tabletOnly }}</td>
              </tr>
              <tr v-if="portalOnly > 0">
                <td>
                  <div class="colorSquare" style="background-color: red"></div>
                </td>
                <td style="color: white; line-height: 32px">Portal Only</td>
                <td>{{ portalOnly }}</td>
              </tr>
              <tr v-if="parkingOnly > 0">
                <td>
                  <div
                    class="colorSquare"
                    style="background-color: black"
                  ></div>
                </td>
                <td style="color: white; line-height: 32px">Parking Only</td>
                <td>{{ parkingOnly }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import Overlay from "ol/Overlay";
import XYZ from "ol/source/XYZ";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { Stroke, Style, Fill, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { FullScreen, defaults as defaultControls } from "ol/control";
import axios from "axios";
import authHeader from "../../services/auth-header";

export default {
  name: "Map",
  data() {
    return {
      map: null,
      x: 383018,
      y: 5967425,
      positions: [],
      layer: null,
      vectorSource: null,
      markersLayer: null,
      overlay: null,
      container: null,
      closer: null,
      content: null,
      locationMarker: null,
      sites: [],
    };
  },
  mounted() {
    this.loadSites();
  },
  methods: {
    loadSites() {
      axios
        .get(
          `https://api.varsanpr.com/api/sites/map?client_id=${this.$store.state.auth.user.selectedClient}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.sites = response.data.sites;
          this.initMap();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initMap() {
      this.container = document.getElementById("popup");
      this.content = document.getElementById("popup-content");
      this.closer = document.getElementById("popup-closer");

      this.overlay = new Overlay({
        element: this.container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });

      this.closer.onclick = () => {
        this.overlay.setPosition(undefined);
        this.closer.blur();
        return false;
      };

      this.map = new Map({
        target: "siteMap",
        controls: defaultControls().extend([new FullScreen()]),
        overlays: [this.overlay],
        layers: [
          new TileLayer({
            source: new XYZ({
              url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
            }),
          }),
        ],
        view: new View({
          center: fromLonLat([-3.4874533187956307, 54.05328679392503]),
          zoom: 7,
        }),
      });

      this.map.on("pointermove", (evt) => {
        let feature = this.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
          return feature;
        });

        if (feature && feature.get("type") == "Point") {
          let coordinate = evt.coordinate;

          this.content.innerHTML = feature.get("desc");
          this.overlay.setPosition(coordinate);
        } else {
          this.overlay.setPosition(undefined);
        }
      });

      this.vectorSource = new VectorSource({});

      this.markersLayer = new VectorLayer({
        source: this.vectorSource,
      });

      this.map.addLayer(this.markersLayer);

      this.sites.forEach((site) => {
        this.addMarker(site, 0);
      });
    },
    addMarker(site, rotation) {
      if (site.tier === null || site.tier === undefined) return;

      let sitePoint = new Point(fromLonLat([site.longitude, site.latitude]));
      sitePoint.rotate(
        (rotation * Math.PI) / 180,
        fromLonLat([site.longitude, site.latitude])
      );

      let siteMarker = new Feature({
        geometry: sitePoint,
        name: site.id,
        type: "Point",
        desc: `<pre>Name: ${site.name}\n# of Cameras: ${site.camCount}\nPostcode: ${site.postcode}</pre>`,
      });

      let color = "black";
      switch (site.tier) {
        case "Full VARS System":
          color = "green";
          break;
        case "Tablet Only":
          color = "orange";
          break;
        case "Portal Only":
          color = "red";
          break;
        case "Parking Only":
          color = "black";
          break;
      }

      let siteStyle = new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: "white", width: 2 }),
        }),
      });

      siteMarker.setStyle(siteStyle);

      this.vectorSource.addFeature(siteMarker);

      this.positions.push({
        id: site.id,
        longitude: site.longitude,
        latitude: site.latitude,
        marker: siteMarker,
        type: site.tier,
        lastStyle: siteStyle,
      });
    },
    showLocation(lat, long, postcode) {
      if (this.locationMarker !== null) {
        this.locationMarker.setStyle(new Style(null));
      }

      let locationPoint = new Point(fromLonLat([long, lat]));

      this.locationMarker = new Feature({
        geometry: locationPoint,
        name: `LocationMarker-${postcode}`,
      });

      let locationStyle = new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: "#AA336A" }),
          stroke: new Stroke({ color: "white", width: 2 }),
        }),
        text: new Text({
          font: "12px Calibri,sans-serif",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 3 }),
          text: postcode,
        }),
        offsetY: 20,
      });

      this.locationMarker.setStyle(locationStyle);
      this.vectorSource.addFeature(this.locationMarker);
      this.map.getView().setCenter(locationPoint.getCoordinates());
      this.map.getView().setZoom(12);
    },
  },
  computed: {
    fullVars: function () {
      return this.sites.filter((s) => {
        return s.tier == "Full VARS System";
      }).length;
    },
    tabletOnly: function () {
      return this.sites.filter((s) => {
        return s.tier == "Tablet Only";
      }).length;
    },
    portalOnly: function () {
      return this.sites.filter((s) => {
        return s.tier == "Portal Only";
      }).length;
    },
    parkingOnly: function () {
      return this.sites.filter((s) => {
        return s.tier == "Parking Only";
      }).length;
    },
  },
};
</script>

<style scoped>
.ol-popup {
  position: absolute;
  background-color: white;
  /*--webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));*/
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 180px;
  color: black;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.colorSquare {
  width: 32px;
  height: 32px;
}
</style>
